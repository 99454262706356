var ua = typeof navigator !== 'undefined' ? navigator.userAgent : '';
var checkSafari = function (ua, minver) {
    var match = ua.match(/(?:Version)[/](\d+(\.\d+)?)/i);
    var ver = parseInt((match && match.length > 1 && match[1] || '0'), 10);
    return (ver >= minver);
};
var checkEdge = function (ua, minver) {
    var match = ua.match(/(?:Edge)[/](\d+(\.\d+)?)/i);
    var ver = parseInt((match && match.length > 1 && match[1] || '0'), 10);
    return (ver >= minver);
};
var checkFirefox = function (ua, minver) {
    var match = ua.match(/(?:Firefox)[/](\d+(\.\d+)?)/i);
    var ver = parseInt((match && match.length > 1 && match[1] || '0'), 10);
    return (ver >= minver);
};
var isAndroid = function () { return /(android)/i.test(ua); };
var isIos = function () { return /iPad|iPhone|iPod/.test(ua) && !window['MSStream']; };
export default {
    ANDROID: isAndroid(),
    OPERA: /opera|opr/i.test(ua) && !/edge/i.test(ua),
    IE: /msie|trident/i.test(ua) && !/edge/i.test(ua),
    IOS: isIos(),
    CHROME: /chrome|crios|crmo/i.test(ua) && !/opera|opr/i.test(ua) && !/edge/i.test(ua),
    FIREFOX: /firefox|iceweasel/i.test(ua) && !/edge/i.test(ua) && checkFirefox(ua, 50),
    FIREFOX_LEGACY: /firefox|iceweasel/i.test(ua) && !/edge/i.test(ua) && !checkFirefox(ua, 50),
    EDGE_CHROMIUM: /edg/i.test(ua) && !/edge/i.test(ua),
    EDGE_WITH_EXTENSION: /edge/i.test(ua) && checkEdge(ua, 14),
    EDGE_LEGACY: /edge/i.test(ua) && !checkEdge(ua, 14),
    SAFARI: /safari/i.test(ua) && !/chrome|crios|crmo/i.test(ua) && !/edge/i.test(ua),
    SAFARI_NO_NPAPI: /safari/i.test(ua) && !/chrome|crios|crmo/i.test(ua) && !/edge/i.test(ua) && checkSafari(ua, 10)
};
