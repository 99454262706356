import { isNullOrUndefinedOrEmpty, generatePromiseData } from '../../../utils';
import { STATUS } from '../../../constants';
import { Logger } from '../../../logger';
/*
 * Abstract base class that holds all code that is common to any extension
 * request handler.
 */
var BaseExtensionStrategy = /** @class */ (function () {
    function BaseExtensionStrategy(options) {
        var _this = this;
        /** Internal cache for active requests */
        this.outstandingRequests = {};
        this.connectStatus = STATUS.INITIALIZING;
        /** Default dialog timeout (24 hours) */
        this.defaultDialogTimeout = 86400000;
        this.changeConnectStatus = function (newConnectStatus) {
            if (_this.connectStatus === newConnectStatus) {
                return;
            }
            _this.connectStatus = newConnectStatus;
            _this.options.requestStatusCallback(newConnectStatus);
        };
        this.httpRequest = function (endpoint, requestId) {
            var requestPromise = generatePromiseData();
            if (endpoint.path.indexOf('/v5/') > -1 || endpoint.path.indexOf('/v6/') > -1) {
                // TODO: Don't mutate original object
                endpoint.path = endpoint.path.replace('/v5', '').replace('/v6', '');
            }
            // Safari extension doesn't accept undefined data even if it is a GET request
            if (isNullOrUndefinedOrEmpty(endpoint.body)) {
                endpoint.body = '';
            }
            var req = {
                'request_id': requestId,
                'min_version': _this.options.minVersion || '',
                'method': endpoint.method,
                'uri_reference': endpoint.path,
                'body': endpoint.body
            };
            /**
             * If it's a dialog api and user sets a request timeout, set the request timeout here.
             * Otherwise, use default 24 hour timeout.
             */
            if (/select/i.test(endpoint.path)) {
                if (_this.options.extensionRequestTimeout) {
                    req.timeout = _this.options.extensionRequestTimeout;
                }
                else {
                    req.timeout = _this.defaultDialogTimeout;
                }
            }
            Logger.debug(req);
            _this.outstandingRequests[requestId] = {
                'req': req,
                'response': '',
                'resolve': requestPromise.resolver
            };
            // TODO: Validate the data length is not over 100MB
            document.dispatchEvent(new CustomEvent('AsperaConnectRequest', { 'detail': req }));
            return requestPromise.promise;
        };
        this.options = options;
    }
    return BaseExtensionStrategy;
}());
export default BaseExtensionStrategy;
