import { __assign } from "tslib";
import 'es6-promise/auto';
import { Connect } from './connect';
import { getGlobalObject } from './core/aspera-web-global.model';
import { ConnectInstaller } from './installer';
import { Logger } from './logger';
import { Utils } from './utils';
var AW4 = { Connect: Connect, ConnectInstaller: ConnectInstaller, Logger: Logger, Utils: Utils };
var windowIntegrations = {};
var _window = getGlobalObject();
// Needed for window integrations with AW4
if (_window.AW4) {
    windowIntegrations = _window.AW4;
}
window.AW4 = __assign(__assign({}, AW4), windowIntegrations);
export * from './exports';
