export var MIN_SECURE_VERSION = '3.9.0';
export var HTTP_METHOD = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    REVERT: 'REVERT'
};
export var STATUS = {
    INITIALIZING: 'INITIALIZING',
    RETRYING: 'RETRYING',
    RUNNING: 'RUNNING',
    OUTDATED: 'OUTDATED',
    FAILED: 'FAILED',
    EXTENSION_INSTALL: 'EXTENSION_INSTALL',
    STOPPED: 'STOPPED',
    WAITING: 'WAITING',
    DEGRADED: 'DEGRADED'
};
export var EVENT = {
    ALL: 'all',
    TRANSFER: 'transfer',
    STATUS: 'status'
};
export var TRANSFER_STATUS = {
    CANCELLED: 'cancelled',
    COMPLETED: 'completed',
    FAILED: 'failed',
    INITIATING: 'initiating',
    QUEUED: 'queued',
    REMOVED: 'removed',
    RUNNING: 'running',
    WILLRETRY: 'willretry'
};
