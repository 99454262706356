import { __assign, __awaiter, __generator } from "tslib";
import { Logger } from '../logger';
import * as Utils from '../utils';
import { MIN_SECURE_VERSION, MAX_POLLING_ERRORS, STATUS } from '../constants';
import { ConnectGlobals } from '../helpers/globals';
import Provider from './strategy/provider';
/*
 * Stategy pattern for selecting the correct request implementation during runtime
 * given the user's configuration options.
 *
 * TODO: Remove dependency on session id. Specific to http strategy.
 */
var RequestHandler = /** @class */ (function () {
    function RequestHandler(_options) {
        var _this = this;
        this._options = _options;
        /** Debugging variable to keep track of multiple instances */
        this._objectId = 0;
        /** Simple counter to increment request ids */
        this._nextId = 0;
        this.versionChecked = false;
        /** Internal cache for active requests */
        this._idRequestHash = {};
        /** Array in which we are going to store all the requests that cannot be processed at this time */
        this._queue = [];
        /** Track number of polling errors for debounce */
        this._pollingRequestErrors = 0;
        /** Internal state of the request handler */
        this._handlerStatus = '';
        /**
         * Process all pending client requests starting with most recent
         */
        this.processQueue = function () {
            var _loop_1 = function () {
                var requestInfo = _this._queue.pop();
                if (requestInfo) {
                    var endpoint = {
                        method: requestInfo.method,
                        path: requestInfo.path,
                        body: requestInfo.body
                    };
                    Logger.debug("Processing request queue for endpoint: ".concat(endpoint.path));
                    _this._strategy.httpRequest(endpoint, requestInfo.requestId)
                        .then(function (response) {
                        return _this.handleResponse(response);
                    })
                        .then(function (response) {
                        if (requestInfo && requestInfo.resolve) {
                            requestInfo.resolve(response);
                        }
                    })
                        .catch(function (error) {
                        // Note: queued activity requests will return 404 by Connect since app_id is empty
                        if (requestInfo && requestInfo.reject) {
                            requestInfo.reject(error);
                        }
                    });
                }
            };
            while (_this._queue.length > 0) {
                _loop_1();
            }
            Logger.debug('Request queue empty.');
        };
        this.changeConnectStatus = function (newConnectStatus) {
            /**
             * Make sure we check the Connect version before going to running. Happens
             * during normal install sequence after initial timeout.
             */
            if (!_this.versionChecked && newConnectStatus === STATUS.RUNNING) {
                void _this.checkVersion();
                return;
            }
            // Workaround for weird safari extension detector logic. We don't want to go to
            // running from outdated unless it's from a version check. ASCN-2271.
            if (Utils.BROWSER.SAFARI &&
                _this.connectStatus === STATUS.OUTDATED &&
                newConnectStatus === STATUS.RUNNING) {
                void _this.checkVersion();
                return;
            }
            // Avoid duplicate event notifications
            if (_this.connectStatus === newConnectStatus) {
                return;
            }
            Logger.debug('[' + _this._objectId + '] Request handler status changing from[' + _this.connectStatus
                + '] to[' + newConnectStatus + ']');
            _this.connectStatus = newConnectStatus;
            if (_this.connectStatus === STATUS.RUNNING) {
                _this.processQueue();
            }
            // Check for status internal to request handler
            if (_this._handlerStatus === STATUS.DEGRADED) {
                void _this.checkVersion(); // Attempt to reconnect
                return;
            }
            // these are handler states - don't bubble up to Connect
            if (newConnectStatus === STATUS.WAITING || newConnectStatus === STATUS.STOPPED) {
                return;
            }
            _this._options.statusListener(_this.connectStatus);
        };
        /**
         * Verify Connect version meets minimum version requirements
         */
        this.checkVersionCallback = function (response) {
            _this.versionChecked = true;
            delete _this._idRequestHash[response.requestId];
            if (Utils.isSuccessCode(response.status)) {
                var parsedResponse = Utils.parseJson(response.body);
                if (Utils.isError(parsedResponse)) {
                    Logger.error('Failed to parse version response: ' + response);
                    return;
                }
                else {
                    ConnectGlobals.connectVersion = parsedResponse.version;
                }
            }
            else if (response.status === 0) {
                Logger.debug('Bad check version response. Retrying...');
                /** Keep trying to check version until connection to the server resumes */
                _this.versionChecked = false;
                setTimeout(function () {
                    void _this.checkVersion();
                }, 500);
                return;
            }
            if (!Utils.checkVersionException()) {
                if (_this._options.minVersion === '' || Utils.versionLessThan(_this._options.minVersion, MIN_SECURE_VERSION)) {
                    _this._options.minVersion = MIN_SECURE_VERSION;
                }
                if (Utils.versionLessThan(ConnectGlobals.connectVersion, _this._options.minVersion)) {
                    /** Check if already in the outdated state. Don't want to notify */
                    /**  event listeners of same status and calling require multiple times. */
                    if (_this.connectStatus !== STATUS.OUTDATED) {
                        _this.changeConnectStatus(STATUS.OUTDATED);
                        /** Trigger update interface in Connect */
                        var requestId = _this._nextId++;
                        var postData = { min_version: _this._options.minVersion, sdk_location: _this._options.sdkLocation };
                        var endpoint = {
                            method: 'POST',
                            path: '/connect/update/require',
                            body: JSON.stringify(postData)
                        };
                        _this.cacheRequest(endpoint, requestId);
                        void _this._strategy.httpRequest(endpoint, requestId);
                    }
                    // Since Connect is outdated, go into a version detection loop
                    var attemptNumber_1 = 1;
                    var check = function () {
                        Logger.debug('Checking for Connect upgrade. Attempt ' + attemptNumber_1);
                        if (Utils.BROWSER.SAFARI) {
                            Logger.debug('Safari upgrade requires a page refresh. Extension context becomes invalidated.');
                        }
                        attemptNumber_1++;
                        if (_this.connectStatus !== STATUS.RUNNING && _this._handlerStatus !== STATUS.STOPPED) {
                            var endpoint = {
                                method: 'GET',
                                path: '/connect/info/version'
                            };
                            var requestId = _this._nextId++;
                            _this._strategy.httpRequest(endpoint, requestId).then(function (response) {
                                var waitUpgradeResponse = Utils.parseJson(response.body);
                                // TODO: Remove duplication here
                                if (Utils.isError(waitUpgradeResponse)) {
                                    Logger.error('Failed to parse version response: ' + response);
                                    return;
                                }
                                if (!Utils.versionLessThan(waitUpgradeResponse.version, _this._options.minVersion)) {
                                    Logger.debug('Updated Connect found.');
                                    clearInterval(connectVersionRetry_1);
                                    // Go back to running state
                                    void _this.checkVersion();
                                }
                            }).catch(function (error) {
                                throw new Error(error);
                            });
                        }
                        else {
                            // If Connect is running, we shouldn't be calling this anymore
                            clearInterval(connectVersionRetry_1);
                        }
                    };
                    // Triggers version check until version response satisfies min version requirement
                    var connectVersionRetry_1 = setInterval(check, 1000);
                    return;
                }
            }
            _this.changeConnectStatus(STATUS.RUNNING);
        };
        /**
         * Helper function to add request to internal cache for request tracking
         */
        this.cacheRequest = function (endpoint, requestId, promiseInfo) {
            var requestInfo = {
                method: endpoint.method,
                path: endpoint.path,
                body: endpoint.body,
                requestId: requestId
            };
            if (promiseInfo) {
                requestInfo.resolve = promiseInfo.resolve;
                requestInfo.reject = promiseInfo.reject;
            }
            _this._idRequestHash[requestId] = requestInfo;
            return requestInfo;
        };
        /**
         * Get Connect version and enforce version requirements
         */
        this.checkVersion = function () { return __awaiter(_this, void 0, void 0, function () {
            var endpoint, requestId, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        endpoint = {
                            method: 'GET',
                            path: '/connect/info/version'
                        };
                        requestId = this._nextId++;
                        this.cacheRequest(endpoint, requestId);
                        return [4 /*yield*/, this._strategy.httpRequest(endpoint, requestId)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            this.checkVersionCallback(response);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        /** Promise that resolves successful client requests. */
        this.handleResponse = function (response) {
            return new Promise(function (resolve, reject) {
                /** Implementation handler might handle this case already */
                if (_this._handlerStatus === STATUS.STOPPED) {
                    Logger.debug('Connect stopped. Skipping request processing.');
                    return reject(Utils.createError(-1, 'Connect is stopped. Skipping request processing.'));
                }
                var requestInfo = _this._idRequestHash[response.requestId];
                if (response.status === 0) {
                    if (_this._pollingRequestErrors < MAX_POLLING_ERRORS &&
                        requestInfo.path.indexOf('/connect/transfers/activity') > 0) {
                        _this._pollingRequestErrors++;
                        return reject(Utils.createError(-1, 'Error processing transfer activity request'));
                    }
                    /** This was a client request, so queue it for processing later. */
                    _this._queue.push(requestInfo);
                    return;
                }
                if (_this.connectStatus !== STATUS.RUNNING) {
                    _this.changeConnectStatus(STATUS.RUNNING);
                }
                var parsedResponse = Utils.parseJson(response.body);
                delete _this._idRequestHash[response.requestId];
                // Reject if response has error fields or if status code is not 2xx
                if (Utils.isError(parsedResponse) || !Utils.isSuccessCode(response.status)) {
                    reject(parsedResponse);
                }
                else {
                    resolve(parsedResponse);
                }
            });
        };
        this.start = function (endpoint) {
            return new Promise(function (resolve, reject) {
                if (_this._handlerStatus === STATUS.STOPPED) {
                    return reject(Utils.createError(-1, 'Connect is stopped. Call #start to resume session.'));
                }
                if (_this._handlerStatus === STATUS.DEGRADED) {
                    return _this.checkVersion(); // Attempt to reconnect
                }
                var requestId = _this._nextId++;
                var requestInfo = _this.cacheRequest(endpoint, requestId, { resolve: resolve, reject: reject });
                /**
                 * If Connect is not ready, queue the client request and resolve the
                 * request when the queue is processed.
                 */
                if (_this.connectStatus !== STATUS.RUNNING) {
                    Logger.debug("Queueing request. Connect is currently ".concat(_this.connectStatus, "."));
                    _this._queue.push(requestInfo);
                    return;
                }
                _this._strategy.httpRequest(endpoint, requestId)
                    .then(function (response) {
                    return _this.handleResponse(response);
                })
                    .then(function (response) {
                    resolve(response);
                })
                    .catch(function (error) {
                    reject(error);
                });
            });
        };
        this.handleFallback = function (response) {
            if (response.status === 0) {
                return;
            }
            var parsedResponse = Utils.parseJson(response.body);
            delete _this._idRequestHash[response.requestId];
            if (Utils.isError(parsedResponse)) {
                return;
            }
            else {
                return parsedResponse;
            }
        };
        this.stopRequests = function () {
            _this._handlerStatus = STATUS.STOPPED;
            if (typeof _this._strategy.stop === 'function') {
                _this._strategy.stop();
            }
            return true;
        };
        this._provider = new Provider(__assign(__assign({}, _options), { requestStatusCallback: this.changeConnectStatus }));
        this._objectId = _options.objectId;
        this._nextId = this._objectId * 10000;
        /** Setup continue event listener */
        window.addEventListener('message', function (evt) {
            if (_this.connectStatus !== STATUS.RUNNING && evt.data === 'continue') {
                if (_this._strategy) {
                    if (_this._strategy.stop) {
                        _this._strategy.stop();
                        _this._strategy = _this._provider.getHttpStrategy();
                    }
                }
            }
        });
    }
    /**
     * Send version or ping requests via the http strategy for debugging.
     */
    RequestHandler.prototype.httpFallback = function (api) {
        return __awaiter(this, void 0, void 0, function () {
            var httpFallback, endpoint, requestId, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        httpFallback = this._provider.getHttpStrategy();
                        endpoint = {
                            path: '/connect/info/' + api,
                            method: 'GET'
                        };
                        requestId = this._nextId++;
                        this.cacheRequest(endpoint, requestId);
                        return [4 /*yield*/, httpFallback.httpRequest(endpoint, requestId)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleFallback(response)];
                }
            });
        });
    };
    /** Define timeout behavior */
    RequestHandler.prototype.handleTimeout = function (timeout) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        /**
                         * Return error message from strategy. Otherwise do some debugging first.
                         */
                        if (timeout.error.user_message !== 'timeout') {
                            throw new Error(timeout.error.user_message);
                        }
                        if (this._handlerStatus === STATUS.STOPPED) {
                            throw new Error('stop() was called');
                        }
                        if (!(this.connectStatus !== STATUS.RUNNING && this.connectStatus !== STATUS.OUTDATED && this.connectStatus !== STATUS.EXTENSION_INSTALL)) return [3 /*break*/, 3];
                        Logger.debug("Connect detection timed out after: ".concat(this._options.connectLaunchWaitTimeoutMs, "ms"));
                        if (this._strategy.name === 'http' || this._strategy.name === 'safari' || this._strategy.name === 'npapi' || this._strategy.name === 'nmh') {
                            this.changeConnectStatus(STATUS.FAILED);
                        }
                        if (!(this._strategy.name === 'nmh')) return [3 /*break*/, 2];
                        if (!(this.connectStatus === STATUS.FAILED)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.httpFallback('version')];
                    case 1:
                        response = _a.sent();
                        if (response && Utils.versionLessThan(response.version, '3.9')) {
                            throw new Error('Incompatible version of Connect detected. You must upgrade to 3.9+.');
                        }
                        else if (response && !Utils.versionLessThan(response.version, '3.9')) {
                            throw new Error('Connect 3.9+ was detected but is not responding to extension requests. Check native message host registration.');
                        }
                        else {
                            throw new Error('Check that Connect 3.9+ is installed.');
                        }
                        _a.label = 2;
                    case 2: 
                    /** Generic timeout error */
                    throw new Error('Timeout reached');
                    case 3:
                        if (this.connectStatus === STATUS.EXTENSION_INSTALL) {
                            throw new Error('Browser extension was not detected. Make sure it is enabled if already installed.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Select request implementation and initialize Connect
     */
    RequestHandler.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, timeoutPromise, timeout;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        /** Reset Connect and handler statuses */
                        this.changeConnectStatus(STATUS.INITIALIZING);
                        this._handlerStatus = '';
                        /** Await implementation selection */
                        Logger.debug('Determining request strategy...');
                        _a = this;
                        return [4 /*yield*/, this._provider.getStrategy()];
                    case 1:
                        _a._strategy = _b.sent();
                        timeoutPromise = new Promise(function (reject) {
                            setTimeout(reject, _this._options.connectLaunchWaitTimeoutMs, Utils.createError(-1, 'timeout'));
                        });
                        return [4 /*yield*/, Promise.race([
                                timeoutPromise,
                                this._strategy.startup()
                            ])];
                    case 2:
                        timeout = _b.sent();
                        if (Utils.isError(timeout)) {
                            return [2 /*return*/, this.handleTimeout(timeout)];
                        }
                        Logger.debug('Connect initialized. Checking version now.');
                        /** Ensure Connect meets version requirements */
                        return [4 /*yield*/, this.checkVersion()];
                    case 3:
                        /** Ensure Connect meets version requirements */
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return RequestHandler;
}());
export default RequestHandler;
